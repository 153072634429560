/** @jsxImportSource @emotion/react */
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Cookies from 'js-cookie';
import { css } from '@emotion/react';
import qs from 'query-string';
import { CommonLoading } from 'react-loadingg';
import Layout from 'components/layout';
import Sidebar from 'components/pages/FTP/Applicants/Sidebar/Sidebar';
import api from 'api/server';
import {
  fullCalendarTimeFormat,
  datePickerToStringFormat,
  convertPhoneNumber,
  handleDownloadTemplate,
  // previousCalendarFormat,
  arrayToStringParser,
  stringToArrayParser,
} from 'helpers';
import { Table, Input } from 'components/common';
import tableConfig from 'components/config/FTP/Applicants/tableConfig';
import {
  containerStyles,
  inputContainerStyles,
  tableHeaderContainerStyles,
  tableBodyStyles,
} from 'components/pages/FTP/Applicants/Applicant.styles';

const Applicant = () => {
  const history = useHistory();
  const queryString = history.location.search;
  const queryObject = qs.parse(queryString) || {};
  const modObj = {
    ...queryObject,
    follow_up_by: typeof (queryObject.follow_up_by) === 'string' ? [queryObject.follow_up_by] : queryObject.follow_up_by,
    payment_types: typeof (queryObject.payment_types) === 'string' ? [queryObject.payment_types] : queryObject.payment_types,
    follow_up_statuses: typeof (queryObject.follow_up_statuses) === 'string' ? [queryObject.follow_up_statuses] : queryObject.follow_up_statuses,
    test_results: typeof (queryObject.test_results) === 'string' ? [queryObject.test_results] : queryObject.test_results,
    applicant_statuses: typeof (queryObject.applicant_statuses) === 'string' ? [queryObject.applicant_statuses] : queryObject.applicant_statuses,
  };
  const [loading, setLoading] = useState(true);
  const [dateRange, setDateRange] = useState([null, null]);
  const [callRange, setCallRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [callDate, callEndDate] = callRange;
  const [filterCondition, setFilterCondition] = useState({
    lastBatch: '',
    paymentType: [],
    followUpStatus: [],
    paymentStatus: '',
    attendanceStatus: '',
    isRegistrationPaid: '',
    applicantStatuses: [],
    testResults: [],
    followUpBy: [],
    search: '',
    call: '',
  });
  const [alertSelect, setAlertSelect] = useState({
    paymentType: false,
    followUpStatus: false,
    testResults: false,
    applicantStatuses: false,
    followUpBy: false,
  });
  const [dataApplicants, setDataApplicants] = useState([]);
  const [pagination, setPagination] = useState({});
  const [isCsvDownloading, setCsvDownloading] = useState(false);

  const csvHeaders = [
    { label: 'Applicant ID', key: 'id' },
    { label: 'Call Attempt', key: 'call' },
    { label: 'Name', key: 'name' },
    { label: 'Email', key: 'email' },
    { label: 'Phone Number', key: 'phone_number' },
    { label: 'Applied date', key: 'applied_date' },
    { label: 'Last Contacted Date', key: 'contacted_date' },
    { label: 'Batch', key: 'last_batch' },
    { label: 'Follow Up Status', key: 'follow_up_status' },
    { label: 'Payment Type', key: 'payment_type' },
    { label: 'Referral Code', key: 'ref_code' },
    { label: 'Is Registration Fee Paid', key: 'is_registration_paid' },
  ];

  async function fetchData() {
    setLoading(true);
    try {
      const { data } = await api.getAllApplicants(modObj);
      const response = data.data;
      if (data) {
        setDataApplicants(response.results);
        setPagination({
          current: response.current_page,
          total: response.last_page,
          totalData: response.total,
        });
      }
    } catch (error) {
      if (error.response.status === 401) {
        Cookies.remove('access_token');
        history.replace('/sign-in');
      }
    } finally {
      setLoading(false);
    }
  }

  async function fetchDataCSV() {
    setCsvDownloading(true);
    try {
      const { data } = await api.getAllApplicants({ ...modObj, limit: 20000 });
      handleDownloadTemplate(data.data.results, csvHeaders, 'applicant_export');
    } catch (error) {
      console.log(error, 'error fetch data csv');
    } finally {
      setCsvDownloading(false);
    }
  }

  useEffect(() => {
    fetchData();
    setFilterCondition(() => ({
      lastBatch: queryObject.last_batch ? { label: queryObject.last_batch, value: queryObject.last_batch } : '',
      followUpBy: stringToArrayParser(queryObject.follow_up_by),
      paymentType: stringToArrayParser(queryObject.payment_types),
      followUpStatus: stringToArrayParser(queryObject.follow_up_statuses),
      paymentStatus: queryObject.payment_status,
      search: queryObject.search,
      attendanceStatus: stringToArrayParser(queryObject.attendance_statuses),
      applicantStatuses: stringToArrayParser(queryObject.applicant_statuses),
      testResults: stringToArrayParser(queryObject.test_results),
      call: queryObject.call,
      isRegistrationPaid: queryObject.is_registration_paid,
    }));
    setDateRange(
      [queryObject.applied_start_date ? new Date(queryObject.applied_start_date) : null,
        queryObject.applied_end_date ? new Date(queryObject.applied_end_date) : null,
      ],
    );
    setCallRange(
      [queryObject.call_start_date ? new Date(queryObject.call_start_date) : null,
        queryObject.call_end_date ? new Date(queryObject.call_end_date) : null,
      ],
    );
  }, [queryString]);

  const applicantData = dataApplicants.map((d) => ({
    key: d.id,
    page: d.id,
    subpage: 'personal-data',
    id: d.id,
    callAttempt: d.call,
    date: fullCalendarTimeFormat(d.applied_date),
    email: d.email,
    name: d.name,
    phone: convertPhoneNumber(d.phone_number),
    batch: d.last_batch,
    payment: d.payment_type,
    followUpStatus: d.follow_up_status?.replaceAll('_', ' '),
    lastContactedDate: fullCalendarTimeFormat(d.contacted_date),
    isPaid: d.is_registration_paid,
    refCode: d.ref_code,
    paymentStatus: d.payment_status,
  }));

  const handleChange = (e) => {
    setFilterCondition({
      ...filterCondition,
      [e.target.name]: e.target.value,
    });
  };

  const handleSelect2Change = (e, name) => {
    setFilterCondition({
      ...filterCondition,
      [name]: e || '',
    });
  };

  const handleResetFilter = () => {
    setFilterCondition({
      lastBatch: '',
      paymentType: [],
      followUpStatus: [],
      attendanceStatus: [],
      paymentStatus: '',
      call: 0,
      search: '',
      is_full_time: true,
      isRegistrationPaid: '',
      applicantStatuses: [],
      testResults: [],
      followUpBy: [],
    });
    setDateRange([null, null]);
    setCallRange([null, null]);
    fetchData().then(() => {
      history.push({
        pathname: history.pathname,
      });
    });
  };

  const handleSubmitFilter = (e) => {
    e.preventDefault();
    const queryFilter = {
      payment_types: arrayToStringParser(filterCondition.paymentType),
      follow_up_statuses: arrayToStringParser(filterCondition.followUpStatus),
      attendance_statuses: arrayToStringParser(filterCondition.attendanceStatus),
      payment_status: filterCondition.paymentStatus,
      test_results: arrayToStringParser(filterCondition.testResults),
      last_batch: filterCondition.lastBatch ? filterCondition.lastBatch.label : '',
      applied_start_date: dateRange[0] ? datePickerToStringFormat(JSON.stringify(dateRange[0])) : '',
      call_start_date: callRange[0] ? datePickerToStringFormat(JSON.stringify(callRange[0])) : '',
      applied_end_date: dateRange[1] ? datePickerToStringFormat(JSON.stringify(dateRange[1])) : '',
      call_end_date: callRange[1] ? datePickerToStringFormat(JSON.stringify(callRange[1])) : '',
      search: filterCondition.search,
      call: filterCondition.call,
      is_registration_paid: filterCondition.isRegistrationPaid,
      is_full_time: true,
      applicant_statuses: arrayToStringParser(filterCondition.applicantStatuses),
      follow_up_by: arrayToStringParser(filterCondition.followUpBy),
    };
    const queryParam = qs.stringify(queryFilter, { skipNull: true, skipEmptyString: true });
    history.push({
      pathname: history.pathname,
      search: queryParam,
    });
  };

  return (
    <Layout
      sidebar={(
        <Sidebar
          startDate={startDate}
          callDate={callDate}
          endDate={endDate}
          callEndDate={callEndDate}
          setDateRange={setDateRange}
          setCallRange={setCallRange}
          filterCondition={filterCondition}
          setFilterCondition={setFilterCondition}
          handleChange={handleChange}
          handleSelect2Change={handleSelect2Change}
          alertSelect={alertSelect}
          setAlertSelect={setAlertSelect}
          handleSubmit={handleSubmitFilter}
          handleReset={handleResetFilter}
          handleDownloadCSV={fetchDataCSV}
          isCsvDownloading={isCsvDownloading}
        />
      )}
    >
      {
        loading ? <CommonLoading /> : (
          <>
            <div css={containerStyles}>
              <span>
                total record: &nbsp;
                {pagination.totalData}
              </span>
              <form css={css`width: 20%;`} onSubmit={handleSubmitFilter}>
                <Input
                  containerStyles={inputContainerStyles}
                  type="text"
                  name="search"
                  value={filterCondition.search}
                  onChange={handleChange}
                  placeholder="search by name / email"
                />
              </form>
            </div>
            <Table
              // containerStyles={css`height: 80vh;`}
              tableHeaderContainerCustomStyles={tableHeaderContainerStyles}
              tableBodyStyles={tableBodyStyles}
              tableConfig={tableConfig}
              history={history}
              pagination={pagination}
              setPagination={setPagination}
              data={applicantData}
            />
          </>
        )
      }
    </Layout>
  );
};
export default Applicant;
