/* eslint-disable */
import dayjs from 'dayjs';
import { Icon } from 'components/common';

const tableConfig = [
  {
    key: 'id',
    label: 'ID',
    ratio: 0.05,
    textAlign: 'left',
    component: (d) => (
      <span style={{ paddingLeft: '5px' }}>
        {d}
      </span>
    ),
  },
  {
    key: 'callAttempt',
    label: 'call',
    ratio: 0.05,
    textAlign: 'center',
    component: (d) => (
      <span>
        {d}
      </span>
    ),
  },
  {
    key: 'date',
    label: 'applied date',
    ratio: 0.15,
    textAlign: 'left',
    component: (d) => (
      <span style={{ textTransform: 'none' }}>
        {d}
      </span>
    ),
  },
  {
    key: 'followUpStatus',
    label: 'Follow up Status',
    ratio: 0.1,
    textAlign: 'left',
    component: (d) => (
      <span>
        {d}
      </span>
    ),
  },
  {
    key: 'lastContactedDate',
    label: 'contacted date',
    ratio: 0.1,
    textAlign: 'left',
    component: (d) => (
      <span>
        {d}
      </span>
    ),
  },
  // {
  //   key: 'email',
  //   label: 'email',
  //   ratio: 0.2,
  //   textAlign: 'left',
  //   component: (d) => (
  //     <span style={{ textTransform: 'none' }}>
  //        {d}
  //     </span>
  //   ),
  // },
  {
    key: 'name',
    label: 'name',
    ratio: 0.2,
    textAlign: 'left',
    component: (d) => (
      <span>
        {d}
      </span>
    ),
  },
  {
    key: 'phone',
    label: 'phone',
    ratio: 0.125,
    textAlign: 'left',
    component: (d) => (
      <span>
        {d}
      </span>
    ),
  },
  {
    key: 'batch',
    label: 'batch',
    ratio: 0.125,
    textAlign: 'left',
    component: (d) => (
      <span>
        {d}
      </span>
    ),
  },
  {
    key: 'payment',
    label: 'payment',
    ratio: 0.1,
    textAlign: 'left',
    component: (d) => (
      <span>
        {d}
      </span>
    ),
  },
  {
    key: 'isPaid',
    label: '100rb paid',
    ratio: 0.05,
    textAlign: 'center',
    component: (d) => d ? <Icon name="check" /> : <Icon name="times" />
  },
  {
    key: 'paymentStatus',
    label: 'payment status',
    ratio: 0.1,
    textAlign: 'left',
    component: (d) => (
      <span>
        {d}
      </span>
    ),
  },
  {
    key: 'refCode',
    label: 'ref Code',
    ratio: 0.05,
    textAlign: 'center',
    component: (d) => d
  },
];

export default tableConfig;
